import { WalletIcons } from '@/ui/common/user-profile/connect-view/connect-view';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@antcorefinance/ui';
import { WalletName, useWallet } from '@aptos-labs/wallet-adapter-react';
import { message } from 'antd';
import Title from 'antd/es/typography/Title';
import axios from 'axios';
import { useState } from 'react';

const FactionJoin = () => {
  const { connected, account, connect, wallets, signMessage } = useWallet();
  const [loading, setLoading] = useState(false);
  const [factionToJoin, setFactionToJoin] = useState<number | any>(null);

  const factions = [
    {
      id: 1,
      name: 'Red Swarm',
      color: 'text-red-600',
      bgcolor: 'bg-red-600',
      ring: 'red',

      description: 'Unite under the Red Swarm banner.',
    },
    {
      id: 2,
      name: 'Blue Nest',
      color: 'text-blue-600',
      bgcolor: 'bg-blue-600',
      ring: 'blue',
      description: 'Organize and strategize with the Blue Nest.',
    },
    {
      id: 3,
      name: 'Purple Hive',
      color: 'text-purple-600',
      ring: 'purple',
      bgcolor: 'bg-purple-600',
      description: 'Innovate and adapt within the Purple Hive.',
    },
  ];

  const handleJoinFaction = async (factionId: number) => {
    if (!connected || !account?.address) {
      setFactionToJoin(factionId); // Store the faction user wants to join
      return;
    }

    setLoading(true);
    const walletAddress = account.address;

    try {
      // Prepare the message payload
      const signMessagePayload = {
        address: true,
        application: true,
        chainId: true,
        message: `I'm verifying account ownership and joining the ${
          factions.find((f) => f.id === factionId)?.name
        } faction.`,
        nonce: new Date().toISOString(), // Use a unique nonce each time
      };

      // Sign the message
      const signResponse = await signMessage(signMessagePayload);

      // Assuming `account.publicKey` provides the public key, add it to the signResponse
      //@ts-ignore
      signResponse.publicKey = account.publicKey;

      // Send the signed message along with the wallet address to your backend
      const response = await axios.post('/api/auth/sign', {
        walletAddress,
        factionId,
        signResponse, // include the signed message response
      });

      setLoading(false);

      if (response.data.success) {
        const faction = factions.find((f) => f.id === factionId);

        message.success({
          content: (
            <div className={`${faction?.bgcolor} rounded-lg`}>
              <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
                <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
                  <div className="flex flex-row gap-3 px-4 py-2">
                    <p className="!mb-0 font-mono !text-sm sm:!text-lg">
                      {' '}
                      Successfully joined the{' '}
                    </p>
                    <p className="!mb-0 !font-mono !text-sm !font-bold sm:!text-lg">
                      {factions.find((f) => f.id === factionId)?.name} Faction
                    </p>
                  </div>
                </Title>
              </div>
            </div>
          ),
          className: 'ant-messagex rounded-lg', // Custom class for the message

          duration: 3, // Keeps the message visible until manually closed
          icon: <></>, // This removes the default icon
        });
      } else {
        const errorMessage = response.data.message || 'Failed to join the faction.';

        message.error({
          content: (
            <div className="rounded-lg bg-red-600">
              <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
                <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
                  <div className="flex flex-row gap-3 px-4 py-2">
                    <p className="!mb-0 font-mono !text-sm sm:!text-lg">{errorMessage}</p>
                  </div>
                </Title>
              </div>
            </div>
          ),
          className: 'ant-messagex rounded-lg',
          duration: 3,
          icon: <></>,
        });
      }
    } catch (error) {
      // Extract error message from Axios error object
      let errorMessage = 'An error occurred while joining the faction.';

      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // Backend returned an error response
          errorMessage = error.response.data.message || error.response.data.error || errorMessage;
        } else if (error.request) {
          // Request was made but no response received
          errorMessage = 'No response received from the server.';
        } else {
          // Something happened in setting up the request
          errorMessage = error.message;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      message.error({
        content: (
          <div className="rounded-lg bg-red-600">
            <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
              <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
                <div className="flex flex-row gap-3 px-4 py-2">
                  <p className="!mb-0 font-mono !text-sm sm:!text-lg">{errorMessage}</p>
                </div>
              </Title>
            </div>
          </div>
        ),
        className: 'ant-messagex rounded-lg',
        duration: 3,
        icon: <></>,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSelectWallet = async (name: WalletName) => {
    try {
      await connect(name); // Await the connect function
      if (factionToJoin) {
        handleJoinFaction(factionToJoin); // Attempt to join the faction again after connection
        setFactionToJoin(null); // Reset the stored faction ID
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  return (
    <div className="mx-auto grid grid-cols-1 gap-8 sm:grid-cols-3">
      {factions.map((faction) => (
        <div key={faction.id} className={`card-border rounded-lg bg-cardBlack p-6`}>
          <h2 className="text-xl font-bold">{faction.name}</h2>
          <p>{faction.description}</p>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
              <button
                className={`mt-4 px-4 py-2 ${faction.bgcolor} focus-visible:ring-ring rounded !ring-green ring-offset-background transition-colors hover:bg-opacity-80 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`}
                onClick={() => handleJoinFaction(faction.id)}
                disabled={loading}
              >
                {connected ? `Join The ${faction.name}` : `Join The ${faction.name}`}
              </button>

              {/* <Button
                variant="na"
                className={`mt-4 px-4 py-2 ${faction.color} rounded-sm`}
                onClick={() => handleJoinFaction(faction.id)}
                disabled={loading}
              >
                {connected
                  ? `Join The ${faction.name}`
                  : `Join The ${faction.name}`}
              </Button> */}
            </DropdownMenuTrigger>
            {!connected && (
              <DropdownMenuContent className="w-56">
                <DropdownMenuGroup>
                  {wallets?.map((wallet) => {
                    const Icon = WalletIcons[wallet.name];
                    return (
                      <DropdownMenuItem
                        onClick={() => onSelectWallet(wallet.name as WalletName)}
                        key={wallet.name}
                      >
                        {Icon && <Icon className="mr-2 h-4 w-4" />}
                        {wallet.name}
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            )}
          </DropdownMenu>
        </div>
      ))}
    </div>
  );
};

export default FactionJoin;
