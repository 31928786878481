import React, { useState } from 'react';
import { Modal, Button, Progress, Typography, message } from 'antd';
import CountUp from 'react-countup';

// import { CheckCircleOutlined } from "@ant-design/icons";
// import { LucideCheckCircle as CheckCircleOutlined } from "lucide-react";
const { Title, Text } = Typography;

//@ts-ignore
const SuccessModal = ({ isVisible, onClose, reward }) => {
  if (isVisible) {
    message.success(
      <Title
        //   level={1}
        className="!text-5xl"
        style={{ color: '#52c41a', marginBottom: 0, paddingBottom: 0 }}
      >
        +
        <CountUp start={0} end={reward} decimals={2} duration={1} separator="," />
      </Title>,
    );
  }
  return (
    <></>
    // <Modal
    //   open={isVisible}
    //   onCancel={onClose}
    //   footer={null}
    //   centered
    //   closeIcon={<span style={{ fontSize: "20px", cursor: "pointer" }}>×</span>}
    // >
    //   <div className="text-center">
    //     <div className="flex flex-row gap-2 justify-center items-center mb-6">
    //       <Title
    //         //   level={1}
    //         className="!text-5xl"
    //         style={{ color: "#52c41a", marginBottom: 0, paddingBottom: 0 }}
    //       >
    //         +
    //         <CountUp
    //           start={0}
    //           end={reward}
    //           decimals={2}
    //           duration={1}
    //           separator=","
    //         />
    //       </Title>
    //       <Text
    //         className="!text-2xl flex self-end"
    //         strong
    //         style={{ color: "#52c41a", fontSize: "16px" }}
    //       >
    //         eANTC ⚡ Points
    //       </Text>
    //     </div>
    //     <Title level={3} style={{ marginTop: "10px" }}>
    //       Congrats!
    //     </Title>
    //     <Text>Task successfully completed!</Text>
    //     {/* <Progress
    //       percent={(20 / 484) * 100}
    //       showInfo={false}
    //       style={{ marginTop: "15px" }}
    //     /> */}
    //     <div
    //       style={{
    //         background: "#f6f8fa",
    //         padding: "10px",
    //         borderRadius: "8px",
    //         marginTop: "25px",
    //       }}
    //     >
    //       <Text>Extra Rewards:</Text>
    //       <div
    //         style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
    //       >
    //         <CheckCircleOutlined
    //           style={{ color: "#52c41a", marginRight: "8px" }}
    //         />
    //         <Text>{reward}</Text>
    //       </div>
    //     </div>
    //   </div>
    // </Modal>
  );
};

export default SuccessModal;
