import { useState, useEffect } from 'react';
import AntModal from './antModal';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { ConnectButton } from '@/ui/common/user-profile/connect-button';
import { ChevronsRight } from 'lucide-react';
import { message, Typography } from 'antd';
import SuccessModal from './successModal';
import CountUp from 'react-countup';
// import { FileWarningIcon } from "lucide-react";
// import { LucideCheckCircle as CheckCircleOutlined } from "lucide-react";

//@ts-ignore
const TaskCard = ({ taskId, taskTitle, taskPoints, taskNumber, subTasks, completedSubTasks }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { connected, account } = useWallet();

  const [isModalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const { Title, Text } = Typography;

  const handleSuccess = (subTaskPoints: any) => {
    setEarnedPoints(subTaskPoints);
    setModalSuccessOpen(true);
  };

  // Initialize the local state with the preloaded completedSubTasks
  const [localCompletedSubTasks, setLocalCompletedSubTasks] = useState(completedSubTasks || []);

  useEffect(() => {
    // Merge preloaded completed tasks into local state when component mounts
    setLocalCompletedSubTasks(completedSubTasks || []);
  }, [completedSubTasks]);

  const handleStartQuest = async () => {
    setIsModalOpen(true);

    if (!connected || !account?.address) {
      return <ConnectButton variant="outline" />;
    }

    // Additional logic for starting the quest...
  };

  //@ts-ignore
  const handleCompleteTask = async (subTaskId, subTaskPoints) => {
    setLoading(true);
    try {
      const response = await fetch('/api/complete-task', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          walletAddress: account?.address,
          taskId: subTaskId,
        }),
      });

      const result = await response.json();
      if (result.success) {
        // Update the local state to reflect the completed task
        setLocalCompletedSubTasks((prev: any) => [...prev, subTaskId]);

        message.success({
          content: (
            <div className="text-center">
              <Title level={3} style={{ marginTop: '10px', color: '#fff' }}>
                Task completed!
              </Title>
              {/* <Text
                style={{
                  color: "#52c41a",

                  marginTop: "10px",
                  marginBottom: "30px",
                }}
              >
                Task successfully completed!
              </Text> */}

              <div className="mb-6 mt-4 flex min-w-[23rem] flex-row items-center justify-center gap-2">
                <Title
                  //   level={1}
                  className="!text-5xl"
                  style={{
                    color: '#52c41a',
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  +
                  <CountUp start={0} end={subTaskPoints} decimals={2} duration={1} separator="," />
                </Title>
                <Text
                  className="flex self-end !text-2xl"
                  strong
                  style={{
                    color: '#52c41a',
                    fontSize: '16px',
                  }}
                >
                  eANTC ⚡ Points
                </Text>
              </div>
            </div>
          ),
          className: 'ant-message rounded-lg', // Custom class for the message

          duration: 4, // Keeps the message visible until manually closed
          icon: <></>, // This removes the default icon
        });
      } else {
        // handleSuccess(subTaskPoints);
        message.error(result.error);
        // alert("Failed to complete task.");
      }
    } catch (error) {
      console.error('Error completing task:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card-shadow card-border min-h-[230px] max-w-[100%] flex-[0_0_100%] rounded-md bg-cardBlack p-4 sm:max-w-[calc(50%-12px)] sm:flex-[0_0_calc(50%-12px)] md:max-w-[calc(33.3%-16px)] md:flex-[0_0_calc(33.3%-16px)]">
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col">
          <div className="contest-card mb-2 font-tiny text-gray-100">{taskNumber}</div>
          <p className="text-lg font-semibold text-gray-100">{taskTitle}</p>
        </div>
        <div>
          <div className="border-antc -mx-4 mb-4"></div>

          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="bottom-card font-tiny text-gray-200">{taskPoints} EANTC ⚡ </div>
            <div
              className="group flex cursor-pointer flex-row text-gray-100 hover:text-white"
              onClick={handleStartQuest}
            >
              <p className="text-gray-300 duration-300 ease-in-out group-hover:text-white">
                {' '}
                START A QUEST
              </p>
              <ChevronsRight className="text-gray-300 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5 group-hover:text-white" />
            </div>
          </div>
        </div>
      </div>

      <SuccessModal
        isVisible={isModalSuccessOpen}
        onClose={() => setModalSuccessOpen(false)}
        reward={earnedPoints}
      />

      <AntModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        taskId={taskId}
        taskTitle={taskTitle}
        subTasks={subTasks}
        onCompleteTask={handleCompleteTask}
        // loading={loading}
        completedSubTasks={localCompletedSubTasks} // Pass the updated state here
      />
    </div>
  );
};

export default TaskCard;
