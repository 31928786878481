// app/Countdown.tsx
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { myServerAction } from './events'; // Ensure correct import path

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const controls = useAnimation();

  useEffect(() => {
    // Fetch initial time left from the server
    const fetchTimeLeft = async () => {
      try {
        const { secondsLeft } = await myServerAction();
        setTimeLeft(secondsLeft);
      } catch (error) {
        console.error('Failed to fetch time left:', error);
      }
    };

    fetchTimeLeft();
  }, []);

  useEffect(() => {
    if (timeLeft === null) return;

    // Start countdown timer on the client
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev !== null && prev <= 1) {
          clearInterval(timer);
          setTimeLeft(4 * 60 * 60); // Reset countdown to 4 hours; server-side reset ensures accuracy
          return 0;
        }
        return prev ? prev - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
    controls.start({
      scale: [1, 1],
      transition: { duration: 0.5 },
    });
  }, [timeLeft, controls]);

  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  if (timeLeft === null) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div
      initial={{ scale: 1 }}
      className="font-mono"
      animate={controls}
      style={{
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#f3f4f6',
      }}
    >
      {formatTime(timeLeft)}
    </motion.div>
  );
};

export default Countdown;
